import { createSlice } from "@reduxjs/toolkit";
import {
  addFilter,
  editFilter,
  removeFilter,
  setChildFilters,
} from "./filtersSlice";
import { addLink, editLink, removeLink, setLinks } from "./menuSlice";
import { setImage, setMain, setSub, setDisclaimerUrl, setWelcomeDisclaimer } from "./welcomeSlice";
import { setAgentCheck, setBotCheck } from "./chatModeSlice";

export const changeSlice = createSlice({
  name: "change",
  initialState: {
    changes: false,
  },
  reducers: {
    setChanges: (state, action) => {
      state.changes = action.payload;
    },
  },
  extraReducers: (builder) => {
    const actions = [
      setChildFilters,
      addFilter,
      removeFilter,
      editFilter,
      setLinks,
      addLink,
      removeLink,
      editLink,
      setMain,
      setSub,
      setImage,
      setAgentCheck,
      setBotCheck,
      setWelcomeDisclaimer,
      setDisclaimerUrl
    ];

    actions.forEach((action) => {
      builder.addCase(action, (state) => {
        state.changes = true;
      });
    });
  },
});

export const { setChanges, setStatus } = changeSlice.actions;
export default changeSlice.reducer;
