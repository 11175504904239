import styled from "@emotion/styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CapLogo } from "../../media/capLogo";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SaveDialog from "../Dialogs/SaveDialog";
import api from "../../apiConfig";
import ImageLoader from "../Loaders/ImageLoader";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function Header({ saveFunction }) {
  const { t } = useTranslation();
  const params = useParams();
  const name = params.bot && params.bot.toUpperCase();
  const [icon, setIcon] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const changes = useSelector((state) => state.change.changes);

  const [isLoading, setIsLoading] = useState(false);
  const currentPage = location.pathname.split("/").pop().replace("-", "");

  useEffect(() => {
    setIsLoading(true);

    api
      .get("/bots")
      .then((res) => {
        const bot = res.data.body.find((item) => item.name === params.bot);
        setIcon(bot ? process.env.PUBLIC_URL + '/icons/' + bot.name + '.svg' : null);

        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to load bot icon");
        console.error(err);
        setIsLoading(false);
      });
  }, [params.bot, name]);

  function handleClick(href) {
    changes === true ? setOpen(true) : navigate(href);
  }

  return (
    <HeaderWrapper>
      <LeftWrapper>
        <button
          onClick={() => {
            handleClick("/bots");
          }}
        >
          <CapLogo />
        </button>
        <SaveDialog
          open={open}
          setOpen={setOpen}
          href={"/bots"}
          saveFunction={saveFunction}
        />
      </LeftWrapper>
      <CenterWrapper>
        <PageTitle>{currentPage === params.bot ? "" : t("header." + currentPage)}</PageTitle>
      </CenterWrapper>
      <RightWrapper>
        <button
          onClick={() => {
            handleClick(`bots/${params.bot}`);
          }}
        >
          <BotWrapper>
            <div>{name}</div>
            {name ? (
              isLoading ? (
                <ImageLoader />
              ) : (
                <Image src={icon} alt={name} />
              )
            ) : null}
          </BotWrapper>
        </button>
        <SaveDialog
          open={open}
          setOpen={setOpen}
          href={`bots/${params.bot}`}
          saveFunction={saveFunction}
        />
      </RightWrapper>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
`;

const PageTitle = styled.div`
  font-weight: 300;
  font-size: var(--font-size-large);
`;

const BotWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  font-size: var(--font-size-large);
`;

const Image = styled.img`
  width: 4.0625rem;
`;
