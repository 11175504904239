import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ThemeProvider } from "@emotion/react";
import { useMemo } from "react";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { darkTheme, lightTheme } from "./components/Themes/Themes";
import BackLayout from "./layout/BackLayout";
import Layout from "./layout/Layout";
import Bots from "./pages/Bots";
import ChatMode from "./pages/ChatMode";
import Features from "./pages/Features";
import Filters from "./pages/Filters";
import Menu from "./pages/Menu";
import Prompt from "./pages/Prompt";
import Unauthenticate from "./pages/Unauthenticate";
import WelcomePage from "./pages/WelcomePage";

function App({ msalInstance }) {
  const currentTheme = useSelector((state) => state.theme.currentTheme);
  const theme = useMemo(
    () => (currentTheme === "light" ? lightTheme : darkTheme),
    [currentTheme],
  );

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ThemeProvider theme={theme}>
          <AuthenticatedTemplate>
            <BrowserRouter>
            <Helmet>
                <style>{`body { background-color: ${theme.backgroundColor}; }`}</style>
            </Helmet>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Navigate to="/bots" replace />} />
                  <Route path="bots" element={<Bots />} />
                  <Route path="bots/:bot/*" element={<BackLayout />}>
                    <Route index element={<Features />} />
                    <Route path="quick-menu" element={<Menu />} />
                    <Route path="welcome-page" element={<WelcomePage />} />
                    <Route path="filters" element={<Filters />} />
                    <Route path="chat-mode" element={<ChatMode />} />
                    <Route path="prompt" element={<Prompt />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route element={<Layout />}>
                  <Route element={<Unauthenticate />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </UnauthenticatedTemplate>
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
