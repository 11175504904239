import Accordion from "../Accordions/Accordion";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import CloseButton from "../Buttons/CloseButton";

export default function HistoryDrawerContent({ history, handleClose, currentPath }) {
  const { t, i18n } = useTranslation();

  function formatDate(date) {
    const locales = i18n.language == "no" ? "nb-NO" : "en-GB"
    const d = new Date(date);
    const formattedDate = d.toLocaleString(
      locales,
      {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit"
      }
    );
    return formattedDate;
  }

  function changesInKeyValueLists(newKeyValueList, oldKeyValueList) {
    // If any of the lists are undefined, return empty list so page does not break
    // This was added as an edge case when loading all history of filters for Juno, root cause not identified
    if (typeof newKeyValueList === 'undefined' || typeof oldKeyValueList === 'undefined') {
      return []
    }
    // keyValueLists look like:
    // [{"key": "key1", "value": "value1"}, {"key": "key2", "value": "value2"}]
    const changes = []
    const oldKeys = []
    const oldKeyValues = {}
    const newKeys = []
    for (let keyValue of oldKeyValueList){
      oldKeys.push(keyValue['key'])
      oldKeyValues[keyValue['key']] = keyValue['value']
    }
    for (let keyValue of newKeyValueList) {
      const currentNewKey = keyValue['key']
      newKeys.push(currentNewKey)
      if (oldKeys.includes(currentNewKey)) {
        if (keyValue['value'] != oldKeyValues[currentNewKey]) {
          changes.push(`${t("history.edit")} ${t(currentNewKey)}`)
        }
      } else {
        changes.push(`${t("history.add")} ${currentNewKey}`)
      }
    }
    for (let oldKey of oldKeys){
      if (!newKeys.includes(oldKey)) {
        changes.push(`${t("history.delete")} ${oldKey}`)
      }
    }
    return changes
  }

  function getDifferences(newString, oldString) {
    if (typeof oldString === 'undefined' || typeof newString === 'undefined') {
      return
    }
    const changes = []
    let newJSON, oldJSON
    try{
      newJSON = JSON.parse(newString)
      oldJSON = JSON.parse(oldString)
    } catch(err){
      return ""
    }
    
    if (currentPath === 'quick-menu') {
      return changesInKeyValueLists(newJSON['parameters'], oldJSON['parameters'])
    } else if (currentPath === 'filters') {
      const changesInFilters = []
      const newFilterKeys = []
      const oldFilterKeys = []
      newJSON['parameters'].map((newFilter) => newFilterKeys.push(newFilter['key']))
      oldJSON['parameters'].map((oldFilter) => oldFilterKeys.push(oldFilter['key']))
      for (let newFilter of newJSON['parameters']) {
        let changesInFilter
        if (oldFilterKeys.includes(newFilter['key'])) {
          const oldIndex = oldJSON['parameters'].findIndex((param) => param['key'] === newFilter['key'])
          changesInFilter = changesInKeyValueLists(newFilter['child_parameters'], oldJSON['parameters'][oldIndex]['child_parameters'])
        } else {
          changesInFilter = changesInKeyValueLists(newFilter['child_parameters'], [])
        }
        for (let value of changesInFilter) {
          changesInFilters.push(`${newFilter['key']}: ${value}`)
        }
      }
      for (let oldFilter of oldJSON['parameters']) {
        if (!newFilterKeys.includes(oldFilter['key'])) {
          const deletedFromFilter = changesInKeyValueLists([], oldFilter['child_parameters'])
          for (let value of deletedFromFilter) {
            changesInFilters.push(`${oldFilter['key']}: ${value}`)
          }
        }
      }
      return changesInFilters
    } else if (currentPath === 'welcome-page') {
      return changesInKeyValueLists(newJSON['parameters'], oldJSON['parameters'])
    } else if (currentPath === 'chat-mode') {
      return []
    } else if (currentPath === 'prompt') {
      return []
    }
    return changes
  }

  function formatContent(content) {
    let contentJSON
    try{
      contentJSON = JSON.parse(content)
    } catch(err){
      return content
    }
    let listToShow = []
    function traverse(obj, number) {
      for (let key in obj) {
        if (key === 'key') {
          listToShow.push(obj)
        }
        if (typeof obj[key] == 'object') {
          traverse(obj[key], number+1)
        }
      }
    }
    traverse(contentJSON, 0)
    if (currentPath === 'quick-menu') {
      listToShow.sort(function (a, b) {
        if (a.key < b.key) {
          return -1
        }
        if (a.key > b.key) {
          return 1
        }
        return 0
      })
    }
    return(
      <>
        {listToShow.map((item, index) => {
          if ('child_parameters' in item) {
            return(<SuperKey key={index}>{item.key}</SuperKey>)
          }
          return (
            <div key={"div"+index}>
              <Key key={"key"+index}>{t(item.key)}</Key>
              <Value key={"value"+index}>{item.value == "" ? <br/> : item.value}</Value>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <>
      <Header>
        <CloseButton handleClose={handleClose}/>
        <TitleWrapper>{t("layout.History")}</TitleWrapper>
      </Header>
      <HistoryWrapper>
        {history &&
          history.map((item, index) => {
            let changes
            if (history[index+1]) {
              changes = getDifferences(item.content, (history[index+1] && history[index+1].content))
            } else {
              changes = getDifferences(item.content, item.content)
            }
            return (
            <Item key={index}>
              <StyledDate>{formatDate(item.date)}</StyledDate>
              <Accordion title={item.description} subtitleList={changes}>
                <PreWrapper>
                  {formatContent(item.content)}
                  {/* {item.content} */}
                </PreWrapper>
              </Accordion>
            </Item>
            );
          })}
      </HistoryWrapper>
    </>

  );
}


const SuperKey = styled.div`
font-weight: 900;
font-size: var(--font-size-large);
`
const Key = styled.div`
font-weight: bold;
font-size: var(--font-size-medium);
`
const Value = styled.div`
font-size: var(--font-size-basis);
`

// const PreWrapper = styled.pre`
//   white-space: pre-wrap;
// `;
const PreWrapper = styled.div`

`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4rem;
`;

const TitleWrapper = styled.div`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x-large);
  align-self: self-start;
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-basis);
  padding-top: 1rem;
`;

const StyledDate = styled.div`
  font-size: var(--font-size-x-small);
`;
