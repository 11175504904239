import axios from "axios";
import { pca } from ".";
import { config } from "./config";

const request = {
  scopes: config.SCOPES,
};

const api = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(
  async (config) => {
    try {
      const response = await pca.acquireTokenSilent(request);
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${response.accessToken}`,
      };

      return config;
    } catch (error) {
      console.error("Failed to acquire token", error);
    }
  },
  (error) => {
    Promise.reject(error);
  },
);

export default api;
